export default [
  {
    path: '/license',
    name: 'apps-license',
    component: () => import('@/views/license/License.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
]