
import { $themeConfig } from '@themeConfig'
import { getScenarioSimplifyList,getrecyclebinList } from "@/libs/api/scenario-item.js";
import { getUserRoleMenu } from "@/libs/api/user";
import { clearTreeAllEmptyChildren } from '@/utils/common'
import { Message } from "element-ui";
// 备注：
// newRoute为左侧菜单list，noScenarioAuthList为不是商户的菜单，hasScenarioAuthList是商户的菜单，不是商户没有Scenarios模块的权限
// 新增菜单时候注意同时往两个数组里面加菜单
const userType = JSON.parse(localStorage.getItem('userData'))?.userType
// let noScenarioAuthList = [
//   {
//     title: 'Welcome',
//     icon: 'HomeIcon',
//     route: 'dashboard',
//   },
//   {
//     title: 'Devices',
//     icon: 'ServerIcon',
//     children:[
//       {
//         title: 'ESLs',
//         route: 'apps-esls',
//       },{
//         title: 'APs',
//         route: 'devices-APs',
//       },{
//         title: 'White List',
//         route: 'devices-Whitelist',
//       },
//     ]
//   },{
//     title: 'Associate',
//     icon: 'LinkIcon',
//     route: 'associate',
//   },{
//     title: 'Task Management',
//     icon: 'TrelloIcon',
//     route: 'task-manger'
//   },
//   {
//     title: 'Logs',
//     icon: 'BookIcon',
//     children: [{
//         title: 'Associations logs',
//         route: 'associations-logs'
//     }, {
//         title: 'Integrations logs',
//         route: 'integrations-logs'
//     }, {
//         title: 'Task logs',
//         route: 'task-logs'
//     }]
//   },{
//     title: 'Layout Builder',
//     icon: 'LayoutIcon',
//     children:[
//       {
//         title: 'Layout',
//         route: 'layout',
//       },{
//         title: 'Template',
//         route: 'template-list',
//       }
//     ]
//   },
//   {
//     title: 'System Management',
//     icon: 'SettingsIcon',
//     children: [
//       {
//         title: 'Customer',
//         route: 'system-management-customer',
//       },{
//         title: 'Stores',
//         route: 'system-management-stores',
//       },{
//         title: 'User Group',
//         route: 'system-management-user-group',
//       },{
//         title: 'User',
//         route: 'system-management-user',
//       },{
//         title: 'Images',
//         route: 'system-management-images',
//       },
//     ]
//   },{
//     title: 'System Configuration',
//     icon: 'BoxIcon',
//     children: [
//       // {
//       //   title: 'Scenario Configuration',
//       //   route: 'scenario-config',
//       // },
//       {
//         title: 'Scenario Configuration',
//         route: 'scenario-config-new',
//       },
//       // {
//       //   title: 'layoutEdit',
//       //   route: 'layout-edit',
//       // },
//       {
//         title: 'System Parameters',
//         route: 'parameters',
//       },{
//         title: 'AP Configuration',
//         route: 'ap-config',
//       },{
//         title: 'Interface Configuration',
//         route: 'interface-config'
//       },
//       // {
//       //   title: 'Email Configuration',
//       // },
//       // {
//       //   title: 'Unit Conversion',
//       //   route: 'apps-Unit',
//       // },
      
//     ],
//   },
//   {
//     title: 'License',
//     route: 'apps-license',
//     icon: 'KeyIcon',
//   },
//   /* {
//     title: 'Devices',
//     icon: 'DevicesIcon',
//     tag: '2',
//     tagVariant: 'light-warning',
//     children: [
//       // {
//       //   title: 'ESls',
//       //   route: 'devices-ESls',
//       // },
//       {
//         title: 'devices',
//         route: 'devices-APs',
//       },
//     ],
//   }, */
//   // {
//   //   title: 'System Conflg',
//   //   icon: 'MailIcon',children: [
//   //     {
//   //       title: 'Conversion',
//   //       route: 'apps-Conversion',
//   //     },]
//   // },
//   // {
//   //   title: 'Chat',
//   //   route: 'apps-chat',
//   //   icon: 'MessageSquareIcon',
//   // },
//   // {
//   //   title: 'Todo',
//   //   route: 'apps-todo',
//   //   icon: 'CheckSquareIcon',
//   // },
//   // {
//   //   title: 'Calendar',
//   //   route: 'apps-calendar',
//   //   icon: 'CalendarIcon',
//   // },
//   // {
//   //   title: 'Invoice',
//   //   icon: 'FileTextIcon',
//   //   children: [
//   //     {
//   //       title: 'List',
//   //       route: 'apps-invoice-list',
//   //     },
//   //     {
//   //       title: 'Preview',
//   //       route: { name: 'apps-invoice-preview', params: { id: 4987 } },
//   //     },
//   //     {
//   //       title: 'Edit',
//   //       route: { name: 'apps-invoice-edit', params: { id: 4987 } },
//   //     },
//   //     {
//   //       title: 'Add',
//   //       route: { name: 'apps-invoice-add' },
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: 'eCommerce',
//   //   icon: 'ShoppingCartIcon',
//   //   children: [
//   //     {
//   //       title: 'Shop',
//   //       route: 'apps-e-commerce-shop',
//   //     },
//   //     {
//   //       title: 'Details',
//   //       route: { name: 'apps-e-commerce-product-details', params: { slug: 'apple-watch-series-5-27' } },
//   //     },
//   //     {
//   //       title: 'Wishlist',
//   //       route: 'apps-e-commerce-wishlist',
//   //     },
//   //     {
//   //       title: 'Checkout',
//   //       route: 'apps-e-commerce-checkout',
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: 'User',
//   //   icon: 'UserIcon',
//   //   children: [
//   //     {
//   //       title: 'List',
//   //       route: 'apps-users-list',
//   //     },
//   //     {
//   //       title: 'View',
//   //       route: { name: 'apps-users-view', params: { id: 21 } },
//   //     },
//   //     {
//   //       title: 'Edit',
//   //       route: { name: 'apps-users-edit', params: { id: 21 } },
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: 'Pages',
//   //   icon: 'FileIcon',
//   //   children: [
//   //     {
//   //       title: 'Authentication',
//   //       icon: 'CircleIcon',
//   //       children: [
//   //         {
//   //           title: 'Login v1',
//   //           route: 'auth-login-v1',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Login v2',
//   //           route: 'auth-login-v2',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Register v1',
//   //           route: 'auth-register-v1',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Register v2',
//   //           route: 'auth-register-v2',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Forgot Password v1',
//   //           route: 'auth-forgot-password-v1',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Forgot Password v2',
//   //           route: 'auth-forgot-password-v2',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Reset Password v1',
//   //           route: 'auth-reset-password-v1',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Reset Password v2',
//   //           route: 'auth-reset-password-v2',
//   //           target: '_blank',
//   //         },
//   //       ],
//   //     },
//   //     {
//   //       title: 'Account Settings',
//   //       route: 'pages-account-setting',
//   //     },
//   //     {
//   //       title: 'Profile',
//   //       route: 'pages-profile',
//   //     },
//   //     {
//   //       title: 'Faq',
//   //       route: 'pages-faq',
//   //     },
//   //     {
//   //       title: 'Knowledge Base',
//   //       route: 'pages-knowledge-base',
//   //     },
//   //     {
//   //       title: 'Pricing',
//   //       route: 'pages-pricing',
//   //     },
//   //     {
//   //       title: 'Blog',
//   //       children: [
//   //         {
//   //           title: 'List',
//   //           route: 'pages-blog-list',
//   //         },
//   //         {
//   //           title: 'Detail',
//   //           route: { name: 'pages-blog-detail', params: { id: 1 } },
//   //         },
//   //         {
//   //           title: 'Edit',
//   //           route: { name: 'pages-blog-edit', params: { id: 1 } },
//   //         },
//   //       ],
//   //     },
//   //     {
//   //       title: 'Mail Templates',
//   //       children: [
//   //         {
//   //           title: 'Welcome',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-welcome.html',
//   //         },
//   //         {
//   //           title: 'Reset Password',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-reset-password.html',
//   //         },
//   //         {
//   //           title: 'Verify Email',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-verify-email.html',
//   //         },
//   //         {
//   //           title: 'Deactivate Account',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-deactivate-account.html',
//   //         },
//   //         {
//   //           title: 'Invoice',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-invoice.html',
//   //         },
//   //         {
//   //           title: 'Promotional',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-promotional.html',
//   //         },
//   //       ],
//   //     },
//   //     {
//   //       title: 'Miscellaneous',
//   //       icon: 'CircleIcon',
//   //       children: [
//   //         {
//   //           title: 'Coming Soon',
//   //           route: 'misc-coming-soon',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Not Authorized',
//   //           route: 'misc-not-authorized',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Under Maintenance',
//   //           route: 'misc-under-maintenance',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Error',
//   //           route: 'misc-error',
//   //           target: '_blank',
//   //         },
//   //       ],
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: 'System Config',
//   //   icon: 'DevicesIcon',
//   //   tagVariant: 'light-warning',
//   //   children: [
//   //     {
//   //       title: 'layoutConfig',
//   //       route: 'system-config',
//   //     },
//   //     {
//   //       title: 'layoutEdit',
//   //       route: 'layout-edit',
//   //     },
//   //     {
//   //       title: 'templateList',
//   //       route: 'template-list',
//   //     },
//   //   ],
//   // },
// ]
// let hasScenarioAuthList = [
//   {
//     title: 'Welcome',
//     icon: 'HomeIcon',
//     route: 'dashboard',
//   },
//   {
//     title: 'Scenarios',
//     icon: 'ShoppingBagIcon',
//     // children: [{
//     //   title: 'items',
//     //   route: 'scenarios-items',
//     // }]
//     children: []
//   },
//   {
//     title: 'Devices',
//     icon: 'ServerIcon',
//     children:[
//       {
//         title: 'ESLs',
//         route: 'apps-esls',
//       },{
//         title: 'APs',
//         route: 'devices-APs',
//       },{
//         title: 'White List',
//         route: 'devices-Whitelist',
//       },
//     ]
//   },{
//     title: 'Associate',
//     icon: 'LinkIcon',
//     route: 'associate',
//   },{
//     title: 'Task Management',
//     icon: 'TrelloIcon',
//     route: 'task-manger',
//   },
//   {
//     title: 'Logs',
//     icon: 'BookIcon',
//     children: [{
//         title: 'Associations logs',
//         route: 'associations-logs'
//     }, {
//         title: 'Integrations logs',
//         route: 'integrations-logs'
//     }, {
//         title: 'Task logs',
//         route: 'task-logs'
//     }]
//   },{
//     title: 'Layout Builder',
//     icon: 'LayoutIcon',
//     children:[
//       {
//         title: 'Layout',
//         route: 'layout',
//       },{
//         title: 'Template',
//         route: 'template-list',
//       }
//     ]
//   },
//   {
//     title: 'System Management',
//     icon: 'SettingsIcon',
//     children: [
//       {
//         title: 'Customer',
//         route: 'system-management-customer',
//       },{
//         title: 'Stores',
//         route: 'system-management-stores',
//       },{
//         title: 'User Group',
//         route: 'system-management-user-group',
//       },{
//         title: 'User',
//         route: 'system-management-user',
//       },{
//         title: 'Images',
//         route: 'system-management-images',
//       },
//     ]
//   },{
//     title: 'System Configuration',
//     icon: 'BoxIcon',
//     children: [
//       // {
//       //   title: 'Scenario Configuration',
//       //   route: 'scenario-config',
//       // },
//       {
//         title: 'Scenario Configuration',
//         route: 'scenario-config-new',
//       },
//       // {
//       //   title: 'layoutEdit',
//       //   route: 'layout-edit',
//       // },
//       {
//         title: 'System Parameters',
//         route: 'parameters',
//       },{
//         title: 'AP Configuration',
//         route: 'ap-config',
//       },{
//         title: 'Interface Configuration',
//         route: 'interface-config'
//       },
//       // {
//       //   title: 'Email Configuration',
//       // },
//       // {
//       //   title: 'Unit Conversion',
//       //   route: 'apps-Unit',
//       // },
      
//     ],
//   },
//   {
//     title: 'License',
//     route: 'apps-license',
//     icon: 'KeyIcon',
//   },
//   /* {
//     title: 'Devices',
//     icon: 'DevicesIcon',
//     tag: '2',
//     tagVariant: 'light-warning',
//     children: [
//       // {
//       //   title: 'ESls',
//       //   route: 'devices-ESls',
//       // },
//       {
//         title: 'devices',
//         route: 'devices-APs',
//       },
//     ],
//   }, */
//   // {
//   //   title: 'System Conflg',
//   //   icon: 'MailIcon',children: [
//   //     {
//   //       title: 'Conversion',
//   //       route: 'apps-Conversion',
//   //     },]
//   // },
//   // {
//   //   title: 'Chat',
//   //   route: 'apps-chat',
//   //   icon: 'MessageSquareIcon',
//   // },
//   // {
//   //   title: 'Todo',
//   //   route: 'apps-todo',
//   //   icon: 'CheckSquareIcon',
//   // },
//   // {
//   //   title: 'Calendar',
//   //   route: 'apps-calendar',
//   //   icon: 'CalendarIcon',
//   // },
//   // {
//   //   title: 'Invoice',
//   //   icon: 'FileTextIcon',
//   //   children: [
//   //     {
//   //       title: 'List',
//   //       route: 'apps-invoice-list',
//   //     },
//   //     {
//   //       title: 'Preview',
//   //       route: { name: 'apps-invoice-preview', params: { id: 4987 } },
//   //     },
//   //     {
//   //       title: 'Edit',
//   //       route: { name: 'apps-invoice-edit', params: { id: 4987 } },
//   //     },
//   //     {
//   //       title: 'Add',
//   //       route: { name: 'apps-invoice-add' },
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: 'eCommerce',
//   //   icon: 'ShoppingCartIcon',
//   //   children: [
//   //     {
//   //       title: 'Shop',
//   //       route: 'apps-e-commerce-shop',
//   //     },
//   //     {
//   //       title: 'Details',
//   //       route: { name: 'apps-e-commerce-product-details', params: { slug: 'apple-watch-series-5-27' } },
//   //     },
//   //     {
//   //       title: 'Wishlist',
//   //       route: 'apps-e-commerce-wishlist',
//   //     },
//   //     {
//   //       title: 'Checkout',
//   //       route: 'apps-e-commerce-checkout',
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: 'User',
//   //   icon: 'UserIcon',
//   //   children: [
//   //     {
//   //       title: 'List',
//   //       route: 'apps-users-list',
//   //     },
//   //     {
//   //       title: 'View',
//   //       route: { name: 'apps-users-view', params: { id: 21 } },
//   //     },
//   //     {
//   //       title: 'Edit',
//   //       route: { name: 'apps-users-edit', params: { id: 21 } },
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: 'Pages',
//   //   icon: 'FileIcon',
//   //   children: [
//   //     {
//   //       title: 'Authentication',
//   //       icon: 'CircleIcon',
//   //       children: [
//   //         {
//   //           title: 'Login v1',
//   //           route: 'auth-login-v1',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Login v2',
//   //           route: 'auth-login-v2',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Register v1',
//   //           route: 'auth-register-v1',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Register v2',
//   //           route: 'auth-register-v2',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Forgot Password v1',
//   //           route: 'auth-forgot-password-v1',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Forgot Password v2',
//   //           route: 'auth-forgot-password-v2',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Reset Password v1',
//   //           route: 'auth-reset-password-v1',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Reset Password v2',
//   //           route: 'auth-reset-password-v2',
//   //           target: '_blank',
//   //         },
//   //       ],
//   //     },
//   //     {
//   //       title: 'Account Settings',
//   //       route: 'pages-account-setting',
//   //     },
//   //     {
//   //       title: 'Profile',
//   //       route: 'pages-profile',
//   //     },
//   //     {
//   //       title: 'Faq',
//   //       route: 'pages-faq',
//   //     },
//   //     {
//   //       title: 'Knowledge Base',
//   //       route: 'pages-knowledge-base',
//   //     },
//   //     {
//   //       title: 'Pricing',
//   //       route: 'pages-pricing',
//   //     },
//   //     {
//   //       title: 'Blog',
//   //       children: [
//   //         {
//   //           title: 'List',
//   //           route: 'pages-blog-list',
//   //         },
//   //         {
//   //           title: 'Detail',
//   //           route: { name: 'pages-blog-detail', params: { id: 1 } },
//   //         },
//   //         {
//   //           title: 'Edit',
//   //           route: { name: 'pages-blog-edit', params: { id: 1 } },
//   //         },
//   //       ],
//   //     },
//   //     {
//   //       title: 'Mail Templates',
//   //       children: [
//   //         {
//   //           title: 'Welcome',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-welcome.html',
//   //         },
//   //         {
//   //           title: 'Reset Password',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-reset-password.html',
//   //         },
//   //         {
//   //           title: 'Verify Email',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-verify-email.html',
//   //         },
//   //         {
//   //           title: 'Deactivate Account',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-deactivate-account.html',
//   //         },
//   //         {
//   //           title: 'Invoice',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-invoice.html',
//   //         },
//   //         {
//   //           title: 'Promotional',
//   //           href: 'https://pixinvent.com/demo/vuexy-mail-template/mail-promotional.html',
//   //         },
//   //       ],
//   //     },
//   //     {
//   //       title: 'Miscellaneous',
//   //       icon: 'CircleIcon',
//   //       children: [
//   //         {
//   //           title: 'Coming Soon',
//   //           route: 'misc-coming-soon',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Not Authorized',
//   //           route: 'misc-not-authorized',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Under Maintenance',
//   //           route: 'misc-under-maintenance',
//   //           target: '_blank',
//   //         },
//   //         {
//   //           title: 'Error',
//   //           route: 'misc-error',
//   //           target: '_blank',
//   //         },
//   //       ],
//   //     },
//   //   ],
//   // },
//   // {
//   //   title: 'System Config',
//   //   icon: 'DevicesIcon',
//   //   tagVariant: 'light-warning',
//   //   children: [
//   //     {
//   //       title: 'layoutConfig',
//   //       route: 'system-config',
//   //     },
//   //     {
//   //       title: 'layoutEdit',
//   //       route: 'layout-edit',
//   //     },
//   //     {
//   //       title: 'templateList',
//   //       route: 'template-list',
//   //     },
//   //   ],
//   // },
// ]
let noScenarioAuthList = [];
let hasScenarioAuthList = [];
const newRoute = (userType === 203 || userType === 204) ? hasScenarioAuthList : noScenarioAuthList
export default {
  namespaced: true,
  state: {
    isVerticalMenuCollapsed: $themeConfig.layout.menu.isCollapsed,
    newRoute: newRoute
  },
  getters: {},
  mutations: {
    UPDATE_VERTICAL_MENU_COLLAPSED(state, val) {
      state.isVerticalMenuCollapsed = val
    },
    updateRouteList(state, val) {
      state.newRoute = val
    },
  },
  actions: {
    async updateRouteList(context) {
      console.log('store里的newRoute菜单值====',userType, context.state.newRoute)
      let val = context.state.newRoute
      if (userType === 203 || userType === 204) {
       
        try {
          let params = {
            merchantId: '',
            textKey:"",
            scenarioKey: "",
            isEffect: 0,
            agentId: ""
          }
          
          const res = await getScenarioSimplifyList(params)
          if (res.status === 200) {
            const data = res.data?.data
            // test 1
            // context.commit('updateRouteList',data)
            // return data
  
  
            console.log('context======', context)
            // test 2 // 存储local
            const newArr = []
            // data.forEach(item => {
            //   newArr.push({
            //     title: item.translateTextName, // defaultTextName
            //     scenarioId: item.scenarioId,
            //     route: { path: `/scenarios/items/${item.scenarioId}`, query: { id: item.scenarioId } }, // 实现 active
            //   })
            // })
            
            data.forEach(item => {
              newArr.push({
                title: item.translateTextName, // defaultTextName
                scenarioId: item.scenarioId,
                route: { name: 'scenarios-items',params: { id: item.scenarioId, subTitle: item.translateTextName } }, // 实现 active
              })
            })
            
            val.forEach(item => {
              if (item.id == 2) { //Scenarios场景路由
                item.children = newArr
              }
            })
            context.commit('updateRouteList',val)
            return data
  
  
            // const newArr = []
            // data.forEach(item => {
            //   newArr.push({
            //     name: 'scenarios-items',
            //     title: item.translateTextName, // defaultTextName
            //     route: { path: `/scenarios/items/${item.scenarioId}`, query: { id: item.scenarioId } },
            //     scenarioId: item.scenarioId,
            //     component: () => import('@/views/Scenarios/index'),
            //   })
            // })
            // val.forEach(item => {
            //   if (item.title === 'Scenarios') {
            //     item.children = newArr
            //   }
            // })
            // context.commit('updateRouteList',val)
            // return val
          } else {
            context.commit('updateRouteList', val)
            return val
          }
        } catch {
          context.commit('updateRouteList', val)
          return val
        }
        
      } else {
        context.commit('updateRouteList', val)
        return val
      }
    },
    getUserRoleMenu({ commit }) {
      return new Promise(async (resolve, reject) => {
        try {
          let { data } = await getUserRoleMenu()
          data = data || {}
          
          if (data.state === true) {
            let list = data.data && data.data.length ? data.data : []
            let listNew = clearTreeAllEmptyChildren(JSON.parse(JSON.stringify(list)))
            commit('updateRouteList',listNew)
            resolve(listNew);
          } else {
            Message.error(data.message);
            reject(data.message);
          }
        } catch(err) {
          Message.error(err);
          reject(err);
        }
      });
    },
  },
}
