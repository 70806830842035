import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'
import request from '@/libs/api/request'
import useJwt from '@/auth/jwt/useJwt'
import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'
import '@/views/svg-icons'
// import element from '@/element/index'
import ElementUI from "element-ui";


// Global Components
import './global-components'
import 'element-ui/lib/theme-chalk/index.css'
import "@/assets/scss/style.scss"
import "@/assets/scss/system-mamagement.scss"
import "@/assets/scss/dashboard.scss"

// 3rd party plugins
import '@axios'
import '@/libs/acl'


// Axios Mock Adapter
// import '@/@fake-db/db'


//注册将要使用的组件
Vue.use(ElementUI, {
  i18n: (key, value) => i18n.t(key, value)
});

import { Loading } from 'element-ui'
Vue.use(Loading.directive)

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false
Vue.prototype.$Api = request
Vue.prototype.post
Vue.prototype.$http = useJwt.axiosIns
new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')
