export default [
  {
    path: '/logs/associations-logs',
    name: 'associations-logs',
    component: () => import ('@/views/logs/associations'),
  },
  {
    path: '/logs/integrations-logs',
    name: 'integrations-logs',
    component: () => import ('@/views/logs/integrations'),
  },
  {
    path: '/logs/task-logs',
    name: 'task-logs',
    component: () => import ('@/views/logs/task'),
  },
]