export default [
  {
    path: '/system-config/scenarioConfig',
    name: 'scenario-config',
    component: () => import('@/views/systemConfig/scenarioConfig/index.vue'),
  },
  {
    path: '/system-config/parameters',
    name: 'parameters',
    component: () => import('@/views/systemConfig/parameters'),
  },
  {
    path: '/system-config/apConfig',
    name: 'ap-config',
    component: () => import('@/views/systemConfig/apConfig'),
  },
  {
    path: '/system-config/interface-config',
    name: 'interface-config',
    component: () => import ('@/views/systemConfig/inface'),
  },
  {
    path: '/system-config/unit-conversion',
    name: 'unit-conversion',
    component: () => import ('@/views/systemConfig/unitConversion'),
  },
  {
    path: '/system-config/email-config',
    name: 'email-config',
    component: () => import ('@/views/systemConfig/emailConfig'),
  },
  {
    path: '/system-config/font-config',
    name: 'font-config',
    component: () => import ('@/views/systemConfig/fontConfig'),
  }

]