export default [
  {
    path: '/system-management/customer',
    name: 'system-management-customer',
    component: () => import('@/views/systemManage/customer/index'),
  },
  {
    path: '/system-management/stores',
    name: 'system-management-stores',
    component: () => import('@/views/systemManage/stores/index'),
  },
  {
    path: '/system-management/user-group',
    name: 'system-management-user-group',
    component: () => import('@/views/systemManage/userGroup/index'),
  },
  {
    path: '/system-management/user',
    name: 'system-management-user',
    component: () => import('@/views/systemManage/user/index'),
  },
  {
    path: '/system-management/images',
    name: 'system-management-images',
    component: () => import('@/views/systemManage/images/index'),
  },
]
