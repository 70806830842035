export default [
  {
    path: '/layoutBuilder/layout',
    name: 'layout',
    component: () => import('@/views/layoutBuilder/layout'),
  },
  {
    path: '/layoutBuilder/layoutEdit',
    name: 'layout-edit',
    component: () => import('@/views/layoutBuilder/layoutEdit'),
  },
  {
    path: '/layoutBuilder/templateList',
    name: 'template-list',
    component: () => import('@/views/layoutBuilder/templateList'),
  },
  {
    path: '/template/svg-edit',
    name: 'template-svg-edit',
    component: () =>import ('@/views/layoutBuilder/templateList/svgEdit.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  },
]