import Vue from 'vue'
import VueI18n from 'vue-i18n'
import elementEnLocale from "element-ui/lib/locale/lang/en"; // element-ui lang
import elementZhLocale from "element-ui/lib/locale/lang/zh-CN"; // element-ui lang
Vue.use(VueI18n)

function loadLocaleMessages() {
  // const locales = require.context('./locales', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const locales = require.context('../../../public/locale', true, /[A-Za-z0-9-_,\s]+\.json$/i)
  const messages = {}
  locales.keys().forEach(key => {
    const matched = key.match(/([A-Za-z0-9-_]+)\./i)
    if (matched && matched.length > 1) {
      const locale = matched[1]
      messages[locale] = locales(key)
    }
  })
  console.log('多语言----messages',messages)
  return messages
}
export default new VueI18n({
  // locale: localStorage.getItem("li18") == 'en_US' ? 'en' : 'zh',
  locale: localStorage.getItem("language") || 'zh',
  globalInjection: true,
  messages: loadLocaleMessages(),
  // messages: {
  //   en: {
  //     ...loadLocaleMessages().en,
  //     ...elementEnLocale
  //   },
  //   zh: {
  //     ...loadLocaleMessages().zh,
  //     ...elementZhLocale
  //   },
  // }
})

