import $http, { $post, $get} from "../axios";
import qs from 'qs'
import config from "../../config/index"
const { baseUrl } = config;

export default {
    devicesDelete: (param) => {  return $http.post(`${baseUrl}/coor/v1/delete`, param)  },
    //基站分配商户
    updateCoorMerchant: (param) => {  
        return $http.post(`${baseUrl}/coor/v1/updateCoorMerchant`, qs.stringify(param), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})  
    },
     //基站分配门店
     updateCoorStore: (param) => {  
        return $http.post(`${baseUrl}/coor/v1/updateCoorStore`, qs.stringify(param), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})  
    },
    //基站未分配商户列表
    getUndistributeMerchantList: (param) => {  
        return $http.post(`${baseUrl}/coor/v1/getUndistributeMerchantList`, param)  
    },
    // 基站未分配门店列表
    getUndistributeStoreList: (param) => {  
        return $http.post(`${baseUrl}/coor/v1/getUndistributeStoreList`, param)  
    },
     // 基站已分配门店列表
     getDistributeList: (param) => {  
        return $http.post(`${baseUrl}/coor/v1/getDistributeList`, param)  
    },
     // 基站删除门店
     deleteStore: (param) => {  
        return $http.post(`${baseUrl}/coor/v1/batchDelete`, qs.stringify(param), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})  
    },
    // 基站更新门店信息
    updateStore: (param) => {  
        return $http.post(`${baseUrl}/coor/v1/update`, param)  
    },
     // 基站查询基站门店信息
     getStoreInfo: (param) => {  
        return $http.post(`${baseUrl}/coor/v1/get`, param)  
    },
    getRecordList: (param) => {  
        return $http.post(`${baseUrl}/coor/v1/getRecordList`, param)  
    },
    switchCoorStore: (param) => {  
        return $http.post(`${baseUrl}/coor/v1/switchCoorStore`, qs.stringify(param), {headers: {'Content-Type': 'application/x-www-form-urlencoded'}})  
    },
    getAllDescendantStores: (param) => {  
        return $http.post(`${baseUrl}/store/v1/getAllDescendantStores`, param, { headers: {'Content-Type': 'application/x-www-form-urlencoded'}})  
    },
    agentMerchantList: (param) => {  
        return $http.post(`${baseUrl}/merchant/v1/agentMerchantList`, param)  
    },
    //布局页接口
    layoutGetList: (param) => {  
        return $post(`${baseUrl}/layout/v1/getList`, param)  
    },
    //创建布局组
    createGroup: (param) => {  
        return  $post(`${baseUrl}/layout/v1/createGroup`, param)  
    },
    //更新布局组
    updateGroup: (param) => {  
        return $post(`${baseUrl}/layout/v1/updateGroup`, param )  
    },
    //获取所有价签类型 
    getAllTagType: (param) => {  
        return $http.post(`${baseUrl}/tag/v1/getAllTagType`, param)  
    },
    //获取场景列表
    getScenarioList: (param) => {  
        return $post(`${baseUrl}/scenario/v1/getScenarioList`, param)  
    },
     //创建布局策略 
    createStrategy: (param) => {  
        return $post(`${baseUrl}/layout/v1/createStrategy`, param)  
    },
    //查询模板列表
    getTemplateList: (param) => {  
        return $post(`${baseUrl}template/v1/getList`, param)  
    },
    //更新布局策略 
    updateStrategy: (param) => {  
        return $post(`${baseUrl}/layout/v1/updateStrategy`, param)  
    },
     //创建布局 
     createLayout: (param) => {  
        return $post(`${baseUrl}/layout/v1/create`, param)  
    },
    //修改布局 
    updateLayout: (param) => {  
        return $post(`${baseUrl}/layout/v1/update`, param)  
    },
     //获取布局信息
     getLayout: (param) => {  
        return $post(`${baseUrl}/layout/v1/get`, param )  
    },
     //批量删除布局策略信息
     batchPhysicalDeleteStrategy: (param) => {  
        return $post(`${baseUrl}/layout/v1/batchPhysicalDeleteStrategy`, param )  
    },
    //模板==============
    //创建模板类型
    createTemplateType: (param) => {  
        return $post(`${baseUrl}/template/type/v1/create`, param )  
    },
    //查找模板类型列表
    getTypeList: (param) => {
        return $post(`${baseUrl}template/type/v1/getList`, param)
    },
    createTemplate: (param) => {  
        return $post(`${baseUrl}/template/v1/create`, param )  
    },
    getTagList: (param) => {  
        return $post(`${baseUrl}/merchant/tag/type/v1/getList`, param )  
    },
    //获取模板信息
    getTemplateInfo: (param) => {  
        return $post(`${baseUrl}/template/v1/get`, param )  
    },
    //获取商品属性信息
    getScenario: (param) => {  
        return $post(`${baseUrl}/scenario/v1/getScenario`, param )  
    },

    // 逻辑删除接口数据
    deleteItemSyncConfig: (param) => {
      return $post(`${baseUrl}item/sync/config/v1/delete`, param)
    },
    // 批量逻辑删除接口数据
    batchdeleteItemSyncConfig: (param) => {
      return $post(`${baseUrl}item/sync/config/v1/batchDelete`, param)
    },
    //获取关联日志列表
    getAssociationLogList: (param) => {
      return $post(`${baseUrl}log/v1/getAssociationLogList`, param)
    },
    //获取集成日志列表
    getIntegrateLogList: (param) => {
      return $post(`${baseUrl}log/v1/getIntegrateLogList`, param)
    },
    // 集成日志获取错误记录
    getErrorList: (param) => {
      return $post(`${baseUrl}log/v1/getErrorList`, param)
    },
    //任务日志获取错误记录
    getErrorDetailList: (param) => {
      return $post(`${baseUrl}schedule/v1/getErrorDetailList`, param)
    },
    //获取任务日志列表
    getTaskScheduleLogList: (param) => {
      return $post(`${baseUrl}log/v1/getTaskScheduleLogList`, param)
    },
    //接口管理====
    //获取接口列表
    getInterfaceList: (param) => {
      return $post(`${baseUrl}item/sync/config/v1/getList`, param)
    },
    //获取场景列表
    getNewScenarioList: (param) => {
      return $post(`${baseUrl}/scenario/v1/getScenarioList`, param)
    },
    //获取场景列表---简化版
    getScenarioSimplifyList: (param) => {
      return $post(`${baseUrl}/scenario/v1/getScenarioSimplifyList`, param)
    },
    // 获取场景详情 scenario/v1/getScenario
    getScenariInfo: (param) => {
      return $post(`${baseUrl}scenario/v1/getScenario`, param)
    },
    // 获取门店列表
    getStoreList: (param) => {
        return $post(`${baseUrl}store/v1/switchPage`, param)
    },
    // 模板导出
    // templateExport: (param) => {
    //     return $post(`${baseUrl}template/v1/export`, param)
    // },
    //模板导入
    // templateImport: (param) => {
    //     return $post(`${baseUrl}template/v1/import`, param)
    // },
    //批量删除模板信息
    batchPhysicalDeleteTemplate: (param) => {
        return $post(`${baseUrl}template/v1/batchDelete`, param)
    },
    // 模板修改
    updateTemplate: (param) => {
        return $post(`${baseUrl}/template/v1/update`, param)
    },
}
