import Vue from 'vue'
import VueRouter from 'vue-router'

// Routes
import { canNavigate } from '@/libs/acl/routeProtection'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser } from '@/auth/utils'


import scenarios from './routes/scenarios'
import devices from './routes/devices'
import associate from './routes/associate'
import taskManagement from './routes/task-management'
import logs from './routes/logs'
import layoutBuilder from './routes/layout-builder'
import systemManagement from './routes/system-management'
import systemConfiguration from './routes/system-configuration'
import license from './routes/license'
import othersPage from './routes/others-page'
import recycle from './routes/recycle'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', redirect: { name: 'dashboard' } },
    ...systemManagement,
    ...systemConfiguration,
    ...layoutBuilder,
    ...logs,
    ...devices,
    ...taskManagement,
    ...scenarios,
    ...associate,
    ...license,
    ...othersPage,
    ...recycle,
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

router.beforeEach((to, _, next) => {
  const isLoggedIn = isUserLoggedIn()
  console.log('to, _, next', to,)
  if (!canNavigate(to)) {
    // Redirect to login if not logged in
    if (!isLoggedIn) return next({ name: 'auth-login' })

    // If logged in => not authorized
    return next({ name: 'misc-not-authorized' })
  }

  // Redirect if logged in
  if (to.meta.redirectIfLoggedIn && isLoggedIn) {
    const userData = getUserData()
    next(getHomeRouteForLoggedInUser(userData ? userData.role : null))
  }

  return next()
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
