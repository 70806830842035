import router from "../axios";

import config from "../../config/index"
const { baseUrl } = config;

// json  data => post   params => get
// form  params =.> get       data => post =>qs.stringify(data)
// 视图部分：  新建时候 调场景详情 接口 getScenario
          // 编辑和修改操作  调视图详情信息接口 getViewDetail   viewListFields对应的是下面store的内容，根据fieldName去匹配数组中的columnName；过滤部分，返现的内容为viewCondition，去匹配translateTextName


// 获取左侧菜单列表
function getScenarioSimplifyList(data) {
  return router({
    url: baseUrl+"scenario/v1/getScenarioSimplifyList",
    data:data,
    method: "POST",
  });
}
// 获取左侧回收站菜单列表
function getrecyclebinList(data) {
  return router({
    url: baseUrl+ "recycle/v1/getPreList",
    data:data,
    method: "POST",
  });
}
// 回收站商品简化列表查询
function sceneimplificlist(data) {
  return router({
    url: baseUrl+ "scenario/v1/getScenarioSimplifyList",
    data:data,
    method: "POST",
  });
}
// 回收站场景简化列表查询
function getRecoveredScenarioSimpList(data) {
  return router({
    url: baseUrl+ "recycle/v1/getRecoveredScenarioSimpList",
    data:data,
    method: "POST",
  });
}
// 回收站批量还原
function reductionList(data) {
  return router({
    url: baseUrl+ "recycle/v1/batchRegain",
    data:data,
    method: "POST",
  });
}
// 回收站批量物理删除
function delectlist(data) {
  return router({
    url: baseUrl+ "recycle/v1/batchPhysicalDelete",
    data:data,
    method: "POST",
  });
}
// 回收站列表查询
function recyclebinlist(data) {
  return router({
    url:  baseUrl+ "recycle/v1/getList",
    data:data,
    method: "POST",
  });
}
// 视图部分
function getViewList(data) {
  // item 获取视图选择数据list下拉框
  return router({
    url: baseUrl+"item/view/v1/getList",
    data: data,
    method: "POST",
  });
}

function getScenario(data) {
  // 获取场景详情
  return router({
    url: baseUrl+"scenario/v1/getScenario",
    data: data,
    method: "POST",
    // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  });
}

function createView(data) {
  // 新建 - 视图 保存
  return router({
    url: baseUrl+"item/view/v1/create",
    data: data,
    method: "POST",
  });
}

function getViewDetail(data) {
  // item 获取视图信息详情 // 视图 编辑修改操作
  return router({
    url: baseUrl+"item/view/v1/get",
    data: data,
    method: "POST",
    // headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  });
}

function updateView(data) {
  // 更新 - 视图
  return router({
    url: baseUrl+"item/view/v1/update",
    data: data,
    method: "POST",
  });
}

function deleteView(data) {
  // 删除视图
  return router({
    url: baseUrl+"item/view/v1/delete",
    data: data,
    method: "POST",
  });
}


//  - item - 列表部分
function getItemList(data) {
  // 获取item 商品列表
  return router({
    url: baseUrl+"item/v1/getList",
    data: data,
    method: "POST",
  });
}


function createItemList(data) {
  // 新增 - 列表 - 保存数据
  return router({
    url: baseUrl+"item/v1/create",
    data: data,
    method: "POST",
  });
}

function getItemDetail(data) {
  // 编辑 - 列表 - 获取详情
  return router({
    url: baseUrl+"item/v1/get",
    data: data,
    method: "POST",
  });
}

function updateItemList(data) {
  // 编辑 - 列表 - 保存更新
  return router({
    url: baseUrl+"item/v1/update",
    data: data,
    method: "POST",
  });
}

function repush(data) {
  // item - 列表 - 重推
  return router({
    url: baseUrl+"item/v1/repush",
    data: data,
    method: "POST",
  });
}

function batchDelete(data) {
  // item - 列表 - 批量删除 
  return router({
    url: baseUrl+"item/v1/batchDelete",
    data: data,
    method: "POST",
  });
}
// 获取模板类型
function getTemplateTypeList(data) {
  return router({
    url: baseUrl+"template/type/v1/getList",
    data: data,
    method: "POST",
  });
}

// 个人模版部分
function getTemplateList(data) {
  // 模版 - 获取列表详情
  return router({
    url: baseUrl+"template/v1/getList",
    data: data,
    method: "POST",
  });
}
// 导出
function exportItem(data) {
  return router({
    url: baseUrl+"item/v1/export",
    data: data,
    responseType: 'blob',
    method: "POST",
  });
}

// 导入 第一步 
function importForFirst(data) {
  return router({
    url: baseUrl+"item/v1/importForFirst",
    data: data,
    method: "POST",
    headers: {'Content-Type': 'application/x-www-form-urlencoded'},
  });
}
// 导入 第二步
function importForSecond(data) {
  return router({
    url: baseUrl+"item/v1/importForSecond",
    data: data,
    method: "POST",
  });
}

// 价签数据

function getTagListByItemId(data) {
  return router({
    url: baseUrl+"tag/v1/getTagListByItemId",
    data: data,
    method: "POST",
  });
}

// 批量修改
function batchUpdate(data) {
  return router({
    url: baseUrl+"item/v1/batchUpdate",
    data: data,
    method: "POST",
  });
}
// 查看历史记录
function getShotsnapList(data) {
  return router({
    url: baseUrl+"item/v1/getShotsnapList",
    data: data,
    method: "POST",
  });
}
export {
  reductionList,
  delectlist,
  recyclebinlist,
  sceneimplificlist,
  getrecyclebinList,
  getScenarioSimplifyList,
  getViewList,
  getScenario,
  getViewDetail,
  createView,
  deleteView,
  updateView,
  getItemList,
  createItemList,
  getItemDetail,
  updateItemList,
  getTemplateList,
  getTemplateTypeList,
  repush,
  batchDelete,
  exportItem,
  importForFirst,
  importForSecond,
  getTagListByItemId,
  getRecoveredScenarioSimpList,
  batchUpdate,
  getShotsnapList
}