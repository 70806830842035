/**
 * 去除字符串两边的空格
*/
export const trim = (str) => {
  if (typeof str !== 'string') {
    throw new Error('str is not string');
  }
  return str.replace(/(^\s*)|(\s*$)/g, "");
};

// 检验---是否是邮箱
export const checkIsEmail = (str) => {
  if (/^[A-Za-z0-9\.\_\-]+@{1}[A-Za-z0-9]+\.{1}[A-Za-z0-9]+[A-Za-z0-9\.]*[A-Za-z0-9]+$/.test(str)) {
    return true;
  } else {
    return false;
  }
};

// 检验---密码
export const checkIsPasswordRegex = (password) => {
  var patt = /^(?![a-zA-Z]+$)(?![A-Z0-9]+$)(?![A-Z\W_!@#$%^&*`~()-+=]+$)(?![a-z0-9]+$)(?![a-z\W_!@#$%^&*`~()-+=]+$)(?![0-9\W_!@#$%^&*`~()-+=]+$)[a-zA-Z0-9\W_!@#$%^&*`~()-+=]{8,16}/
  return patt.test(password)
}

// 校验---字符串是否为 为数字、字母及下划线
export const checkKeyRegex = (key) => {
  var patt = /^\w+$/
  return patt.test(key)
}

//导出excel
export const downLoad = (data, fileName) => {
  if (data) {
    const link = document.createElement("a");
    const blob = new Blob([data], {
      type: "application/vnd.ms-excel;charset=utf-8",
    });
    link.style.display = "none";
    link.href = URL.createObjectURL(blob);
    link.setAttribute("download", `${fileName}.xls`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

// 展示百分比
export const keepPercentage = (number) => {
  if (number.toString().includes('.')){
    let list = number.toString().split('.')
    let left = list[0] * 100
    let right = list[1]
    return left + '.' + right + '%'
  } else {
    return number * 100 + '%'
  }
}

// 保留千分位
export const keepThousands = (number) => {
  // console.log('keepThousandsnumber====', number)
  if (number === null) return
  if (!number && Number(number) !== 0) return
  number = number.toString()
  if (number.includes('.')) {
    let list = number.split('.')
    let left = Number(Number(list[0]).toFixed(2)).toLocaleString();
    let right = list[1]
    return left + '.' + right
  } else {
    number = Number(Number(number).toFixed(2)).toLocaleString();
    return number
  }
}

// 保留n位小数
export const keepDecimal = (_m_, _n_) => {
  let num = Number(_m_);
  let n = Number(_n_);
  if (!isNaN(num) && !isNaN(n)) {
    if (num !== 0) {
      // const list = [...Math.floor(num * 10 ** n).toString()];
      // console.log('list的值-------keepDecimal====', list)
      // list.splice(-n, 0, ".");
      // return list.join("");
      num = num.toFixed(n)
      return num
    } else {
      let str = '.'
      for(var i = 0; i < n; i++) {
        str = str.concat('0')
      }
      return str
    }
  }
}

// 自定义数字类型展示
export const handleNumber = {
  custom1(x){
    // 四舍五入，保留整数
    return Number(x).toFixed(0)
  },
  custom2(x){
    // 四舍五入，保留两位小数
    return Number(x).toFixed(2)
  },
  custom3(x){
    // 四舍五入，保留整数，并加上千分符
    let num = Number(Number(x).toFixed(0)).toLocaleString();
    return num
  },
  custom4(x){
    // 四舍五入，保留两位小数，并加上千分符
    let num = Number(Number(x).toFixed(2)).toLocaleString();
    return num
  },
  custom5(x){
    // 四舍五入，保留整数，并加上千分符,并加上 ￥
    let num = Number(Number(x).toFixed(0)).toLocaleString("zh-CN", {style:"currency", currency:"CNY"})
    num = num.slice(0,num.length-3)
    console.log('处理custom5',num,typeof(num))
    return num
  },
  custom6(x){
    // 四舍五入，保留两位小数，并加上千分符,并加上 ￥
    let num = Number(Number(x).toFixed(2)).toLocaleString("zh-CN", {style:"currency", currency:"CNY"})
    // num = num.slice(0,num.length-3)
    console.log('处理custom6',num,typeof(num))
    return num
  },
  custom7(x){
    // 四舍五入，保留整数，并加上千分符,并加上 $
    let num = Number(Number(x).toFixed(0)).toLocaleString("en-US", {style:"currency", currency:"USD"})
    num = num.slice(0,num.length-3)
    return num
  },
  custom8(x){
    // 四舍五入，保留两位小数，并加上千分符,并加上 $
    let num = Number(Number(x).toFixed(2)).toLocaleString("en-US", {style:"currency", currency:"USD"})
    return num
  },
  custom9(x){
    return Math.trunc(x)
  },
  custom10(x){
    return Math.trunc(x)
  },
  ceil(x){
    // 对 x 进行上舍入。向上取整计算
    return Math.ceil(x)
  },
  floor(x){
    // 对 x 进行下舍入。向下取整计算
    return Math.floor(x)
  },
  round(x){
    // 四舍五入。把一个数字舍入为最接近的整数
    return Math.round(x)
  },
  trunc(x){
    // 将数字的小数部分去掉，只保留整数部分。
    return Math.trunc(x)
  },

}

// 删除children为[]的属性
export const clearTreeAllEmptyChildren = (data) => {
  data = data.filter((item) => {
    if (item.children) item.children = clearTreeAllEmptyChildren(item.children)
    if (item.children && item.children.length === 0) delete item.children
    return item
  });
  return data
};


export const dateFormat = (date, format) => {
  var o = {
    'M+': date.getMonth() + 1, // month
    'd+': date.getDate(), // day
    'H+': date.getHours(), // hour
    'm+': date.getMinutes(), // minute
    's+': date.getSeconds(), // second
    'q+': Math.floor((date.getMonth() + 3) / 3), // quarter
    'S+': date.getMilliseconds()
  }

  if (/(y+)/.test(format)) {
    format = format.replace(
      RegExp.$1,
      (date.getFullYear() + '').substr(4 - RegExp.$1.length)
    )
  }

  for (var k in o) {
    if (new RegExp('(' + k + ')').test(format)) {
      var formatStr = ''
      for (var i = 1; i <= RegExp.$1.length; i++) {
        formatStr += '0'
      }

      var replaceStr = ''
      if (RegExp.$1.length == 1) {
        replaceStr = o[k]
      } else {
        formatStr = formatStr + o[k]
        var index = ('' + o[k]).length
        formatStr = formatStr.substr(index)
        replaceStr = formatStr
      }
      format = format.replace(RegExp.$1, replaceStr)
    }
  }
  return format
}