export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/index'),
  },
  // 登录
  {
    path: '/login',
    name: 'auth-login',
    component: () => import('@/views/user/login/index.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      redirectIfLoggedIn: false,
    },
  },
  // 个人信息
  {
    path: '/user/personal-information',
    name: 'personal-information',
    component: () => import('@/views/user/personalInformation/index'),
    // meta: {
    //   pageTitle: '',
    //   breadcrumb: [
    //     { text: 'Information',active: true,},
    //   ],
    // },
  },
  // 关于我们
  {
    path: '/about-us',
    name: 'about-us',
    component: () => import('@/views/user/aboutUs/index'),
  },
  // 大屏
  {
    path: '/dashboard/lscreen',
    name: 'dashboard-lscreen',
    component: () => import ('@/views/dashboard/index'),
    meta: {
      layout: 'full'
    },
  },
  // 404页面
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/error/Error404.vue'),
    meta: {
      layout: 'full',
      resource: 'Auth',
      action: 'read',
    },
  }
]