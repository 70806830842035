import router from "../axios";
// import qs from 'qs'

import config from "../../config/index"
const { baseUrl } = config;

function login(data) {
  //登录
  return router({
    url: baseUrl+"login/v1/pc/password",
    data:data,
    method: "POST"
  });
}

// 获取用户信息
function getUser(data) {
  return router({
    url: baseUrl+"user/v1/get",
    data:data,
    method: "POST",
  });
}

// 我的用户信息修改
function ownerInfoUpdate(data) {
  return router({
    url: baseUrl+"user/v1/ownerInfoUpdate",
    data:data,
    method: "POST",
  });
}

// 重置密码
function updatePassword(data) {
  return router({
    url: baseUrl+"user/v1/updatePassword",
    data:data,
    method: "POST",
  });
}

// 获取当前登录用户所属角色的菜单信息
function getUserRoleMenu(data) {
  return router({
    url: baseUrl+"userRole/v1/getUserRoleMenu",
    data:data,
    method: "POST",
  });
}

// 获取消息数量
function systemMessage(data) {
  return router({
    url: baseUrl+"systemMessage/v1/countUnRead",
    data:data,
    method: "POST",
  });
}


export {
  login,
  getUser,
  ownerInfoUpdate,
  updatePassword,

  getUserRoleMenu,
  systemMessage
}