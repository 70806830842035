export default [
  {
    path: '/devices/esls',
    name: 'apps-esls',
    // component: () => import('@/views/devices/esls.vue'),
    component: () => import('@/views/devices/esls/index.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  {
    path: '/devices/esls',
    name: 'devices-esls',
    // component: () => import('@/views/devices/esls.vue'),
    component: () => import('@/views/devices/esls/index.vue'),
    meta: {
      contentRenderer: 'sidebar-left',
      contentClass: 'email-application',
    },
  },
  { 
    path: '/devices/APs',
    name: 'devices-APs',
    // component: () => import('@/views/devices/APs'),
    component: () => import('@/views/devices/aps/index'),
    
  },
  { 
    path: '/devices/Whitelist',
    name: 'devices-Whitelist',
    // component: () => import('@/views/devices/index'),
    component: () => import('@/views/devices/whiteList'),
  }
]