import { Message } from 'element-ui'
import qs from 'qs'

import axios from 'axios'

const instance = axios.create({
  timeout: 300000,
})
// 添加请求拦截器
instance.interceptors.request.use(config =>
// config.headers["Content-Type"] = "application/json;charset=utf-8"
// config.headers["Content-Type"] = "application/x-www-form-urlencoded"

  config,
error =>
// 对请求错误做些什么
  Promise.reject(error))

// 添加响应拦截器
instance.interceptors.response.use(response =>
// if (response.status === 200 && response.data.code == 1) {
//     return Promise.resolve(response.data);
// } else {
//     Message.error(response.data.message);
//     return Promise.reject(response.data);
// }
  Promise.resolve(response),
// 对响应数据做点什么
// return response.data;
error =>
// 对响应错误做点什么
  Promise.reject(error))
/*
//get请求
param:{url:请求路径}
return：{*}
 */
export default instance

/**
 * get 请求方法
 * @param url
 * @param params
 * @returns {Promise}
 */
export function $get(url, params = {}) {
  return new Promise((resolve, reject) => {
    instance.get(url, { params }).then(response => {
      if (response.status === 200 && response.data.code == 1) {
        return resolve(response.data)
      }
      Message.error(response.data.message)
      return reject(response.data)

      // resolve(response)
    }).catch(err => {
      reject(err)
    })
  })
}

/**
 * post 请求方法
 * @param url
 * @param data
 * @returns {Promise}
 */
export function $post(url, data = {}, type) {
  return new Promise((resolve, reject) => {
    // qs.stringify(data)
    const params = { data }
    if (type == 'form-urlencoded') {
      params.data = qs.stringify(data)
      params.config = { headers: { 'Content-Type': 'application/x-www-form-urlencoded' } }
    }
    instance.post(url, params.data, params.config || type).then(
      response => {
        if (response.status === 200 && response.data.code == 1) {
          return resolve(response.data)
        }
        Message.error(response.data.message)
        return reject(response.data)

        // console.log(response.data.code)
        // resolve(response)
      },
      err => {
        Message.error(err.message)
        reject(err)
      },
    )
  })
}
