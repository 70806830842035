export default [
  {
    path: '/task-management',
    name: 'task-manger',
    component: () => import ('@/views/taskManagement/taskManger'),
  },
  {
    path: '/task-list',
    name: 'task-list',
    component: () => import ('@/views/taskManagement/taskList'),
  }
]

